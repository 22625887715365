import { useState } from "react";
import { getCookie, setCookie } from "@/utils/cookies.ts";

function useCookie<T>(key: string, value: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (import.meta.env.SSR) {
      return value;
    }

    return getCookie(key);
  });

  const setValue = (value: T | ((val: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    setCookie(key, valueToStore);
  };

  return [storedValue, setValue] as const;
}

export default useCookie;
