import useCookie from "@/hooks/useCookie.ts";
import React, { useEffect, useRef } from "react";
import { deleteCookie } from "@/utils/cookies.ts";

const Alert: React.FC = () => {
  const ref = useRef<HTMLDialogElement>(null);

  const [alertTitle] = useCookie("alert#title", "");
  const [alertContent] = useCookie("alert#content", "");

  const canNotShow = !(
    alertTitle &&
    alertTitle.length > 0 &&
    alertContent &&
    alertContent.length > 0
  );

  useEffect(() => {
    if (canNotShow) {
      return;
    }

    setTimeout(() => {
      if (ref.current) {
        ref.current.showModal();
      }

      deleteCookie("alert#title");
      deleteCookie("alert#content");
    });
  }, []);

  if (canNotShow) {
    return null;
  }

  return (
    <dialog ref={ref} className={"modal modal-bottom lg:modal-middle"}>
      <div className={"modal-box"}>
        <form method="dialog">
          <h1 className={"font-semibold"}>{alertTitle}</h1>
          <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 rtl:left-2 rtl:right-[unset]">
            ✕
          </button>
        </form>

        <p className={"font-medium break-words my-5"}>{alertContent}</p>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

export default Alert;
